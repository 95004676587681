import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreConfigService } from '@ideenherd/core/configuration';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';
import { ConvertHelper } from './helpers';

@Injectable({
    providedIn: 'root'
})
export class PersistenceService {
    private startAppointmentUri: string;
    private appointmentByIdUri: string;
    private userWorkShopdUri: string;
    private userEventByIdUri: string;
    private usersWorkshopUri: string;
    private usersEventsAccreditationUri: string;

    constructor(private http: HttpClient, private configService: CoreConfigService, private dataService: DataService) {
        this.startAppointmentUri = this.configService.configuration.WebApi.ServiceURLs.StartAppointment;
        this.appointmentByIdUri = this.configService.configuration.WebApi.ServiceURLs.AppointmentById;
        this.userWorkShopdUri = this.configService.configuration.WebApi.ServiceURLs.UsersWorkshopById;
        this.userEventByIdUri = this.configService.configuration.WebApi.ServiceURLs.UsersEventById;
        this.usersWorkshopUri = this.configService.configuration.WebApi.ServiceURLs.UsersWorkshop;
        this.usersEventsAccreditationUri = this.configService.configuration.WebApi.ServiceURLs.UsersEventsAccreditation;


    }

    public addAppointment(query): Observable<any> {
        return this.dataService.executeWebApiPostMethod((ConvertHelper.resolveStringPlaceholders(this.startAppointmentUri, this.dataService.tenant)), query);
    }

    public updateAppontmentById(id, query): Observable<any> {
        return this.dataService.executeWebApiPatchMethod((ConvertHelper.resolveStringPlaceholders(this.appointmentByIdUri, this.dataService.tenant, id)), query);
    }

    public updateUserWorkShopById(id, query): Observable<any> {
        return this.dataService.executeWebApiPatchMethod((ConvertHelper.resolveStringPlaceholders(this.userWorkShopdUri, this.dataService.tenant, id)), query);
    }
    public updateUserEventById(id, query): Observable<any> {
        return this.dataService.executeWebApiPatchMethod((ConvertHelper.resolveStringPlaceholders(this.userEventByIdUri, this.dataService.tenant, id)), query);
    }
    public insertUserWorkShop(query): Observable<any> {
        return this.dataService.executeWebApiPostMethod((ConvertHelper.resolveStringPlaceholders(this.usersWorkshopUri, this.dataService.tenant)), query);
    }
    public deleteUserWorkShop(id): Observable<any> {
        return this.dataService.executeWebApiDeleteMethod((ConvertHelper.resolveStringPlaceholders(this.userWorkShopdUri, this.dataService.tenant, id)));
    }
    public insertUsersEventsAccreditation(query): Observable<any> {
        return this.dataService.executeWebApiPostMethod((ConvertHelper.resolveStringPlaceholders(this.usersEventsAccreditationUri, this.dataService.tenant)), query);
    }

}

export interface IAppointment {
    a_user_id: number;
    users?: string[];
    a_additional_users?: string;
    a_car_id: number;
    a_event_id: number;
    a_check_in_time: Date;
    a_check_out_time?: Date;
}




